// @flow
import React from 'react';
import Title from "../../components/app/title/title";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TablePagination from "@material-ui/core/TablePagination";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    actions: {
        width: 100,
    },
}));

function createData(id, name, model, location, status) {
    return { id, name, model, location, status };
}

const rows = [
    createData(0, 'HP Color Detroit', 'HP LaserJet 502', 'Detroit', 'Online'),
    createData(1, 'HP Color Detroit', 'HP LaserJet 502', 'Detroit', 'Online'),
    createData(2, 'HP Color Detroit', 'HP LaserJet 502', 'Detroit', 'Online'),
    createData(3, 'HP Color Detroit', 'HP LaserJet 502', 'Detroit', 'Online'),
    createData(4, 'HP Color Detroit', 'HP LaserJet 502', 'Detroit', 'Online'),
];

const PrinterList = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Title>Printers</Title>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Model</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell className={classes.actions}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.model}</TableCell>
                                <TableCell>{row.location}</TableCell>
                                <TableCell>{row.status}</TableCell>
                                <TableCell>
                                    <Tooltip title="Edit">
                                        <IconButton size='small'>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton size='small'>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={5}
                page={0}
                onChangePage={() => {}}
            />
        </React.Fragment>
    );
};

export default PrinterList;