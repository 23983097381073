// @flow
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';

export type SetAuthenticatedAction = {|
    type: 'SET_AUTHENTICATED',
    payload: {|
        isAuthenticated: boolean,
    |},
|}

export const setAuthenticated = (isAuthenticated: boolean): SetAuthenticatedAction => ({
    type: SET_AUTHENTICATED,
    payload: {
        isAuthenticated: isAuthenticated,
    }
});