// @flow

import React from 'react';
import Title from "../../components/app/title/title";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

function createData(id, name, status) {
    return { id, name, status };
}

const rows = [
    createData(0, 'Printer 1', 'Online'),
    createData(1, 'Printer 2', 'Online'),
    createData(2, 'Printer 3', 'Online'),
    createData(3, 'Printer 4', 'Online'),
];


const PrinterStatus = () => {
    return (
        <React.Fragment>
            <Title>Favourite printers</Title>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
};

export default PrinterStatus;