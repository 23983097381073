// @flow

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {useSelector} from "react-redux";
import {getAuthenticated} from "../../store/selectors";
import routes from "../../routes";

export type Props = {
    children: mixed,
    ...
};

const PrivateRoute = ({ children, ...rest }: Props) => {
    const isAuthenticated = useSelector(getAuthenticated);

    return (
        <Route
            {...rest}
            render={({ location }) => isAuthenticated
                ? children
                : <Redirect to={{
                    pathname: routes.login,
                    state: { referrer: location },
                }}
                />
            }
        />
    );
};

export default PrivateRoute;