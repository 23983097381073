// @flow
import React from 'react';
import clsx from 'clsx';
import withStyles from "@material-ui/core/styles/withStyles";
import capitalize from "@material-ui/core/utils/capitalize";
import type {Theme} from "@material-ui/core";

export type Props = {
    classes: {|
        root: string,
        colorPrimary: string,
        colorSecondary: string,
    |},
    className: string,
    color: string,
    children: any,
    width: number,
    height: number,
    ...
}

export type Styles = {|
    root: {|
        fill: string,
    |},
    colorPrimary: {|
        color: string,
    |},
    colorSecondary: {|
        color: string,
    |},
|}

export const styles = (theme: Theme): Styles => ({
    root: {
        fill: 'currentColor',
    },
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
});

const SvgImage = ({classes, className, color, children, width, height, ...rest}: Props) => <svg
    {...rest}
    viewBox={`0 0 ${width} ${height}`}
    className={clsx(
        classes.root,
        {
            [classes[`color${capitalize(color)}`]]: color !== 'inherit',
        },
        className,
    )}
>
    {children}
</svg>;

export default withStyles(styles)(SvgImage);