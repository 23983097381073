// @flow
import React from 'react';
import SvgImage from "../components/svg-image";

export type Props = {
    ...
}

const EcolabLogo = (props: Props) => <SvgImage {...props} width={365} height={70}>
    <g transform="translate(-67.5,-215)">
        <path d="m 204.8,241.6 c -1.4,-0.1 -2.3,0.2 -3.3,0.5 -7,-4.1 -14.7,-8.8 -21.7,-12.1 6.2,4.9 12.8,9.3 18.4,14.7 -0.7,0.1 -0.5,1 -1.2,1.2 -6.8,-3.6 -13.9,-8.1 -20.5,-11.2 6.6,5 13.2,9.9 19.6,15.2 -0.2,1.3 0.2,2.1 0.5,3 -3.8,7.3 -8.7,14.8 -11.9,21.9 4.8,-6.2 9.1,-12.8 14.5,-18.4 0.1,0.7 1,0.5 1.2,1.2 -3.3,7 -8.1,13.8 -11,20.5 4.8,-6.7 9.9,-13.1 14.9,-19.6 1.4,0.1 2.3,-0.2 3.3,-0.5 7,4.1 14.7,8.6 21.7,11.9 -6.3,-4.6 -12.5,-9.3 -18.4,-14.2 0.6,-0.2 0.6,-1.1 1.2,-1.4 6.9,3.4 13.9,8 20.5,11 -6.6,-4.9 -13,-9.8 -19.4,-14.9 -0.1,-1.1 -0.5,-2 -0.5,-3.3 3.9,-7.1 8.6,-14.7 11.9,-21.7 -4.8,6.2 -9.3,12.6 -14.5,18.4 -0.4,-0.4 -1,-0.7 -1.4,-1.2 3.6,-6.8 8.1,-14 11.2,-20.5 -4.9,6.5 -9.8,13.2 -15.1,19.5 M 202.5,215 h 4.2 c 12.6,1.2 21.4,7.1 27,15.9 5.8,9 7.9,22.4 2.6,33.8 -5.2,11.2 -14.5,18.5 -29.6,20.3 h -4.2 c -10,-0.7 -17.5,-5.3 -23.1,-11 -5.6,-5.7 -9.8,-14 -9.8,-24 0,-20.2 14.6,-32.8 32.9,-35"/>
        <path d="m 315.8,257.9 h 12.4 c -2.2,-7.5 -3.7,-15.5 -6.3,-22.6 -2.1,7.4 -4,15.1 -6.1,22.6 m -9.5,-41 h 31.9 c 6.8,22.3 13.9,44.3 20.8,66.5 h -24.3 c -0.6,-3.2 -1.5,-6 -2.3,-8.9 h -20.8 c -0.9,2.8 -1.8,5.7 -2.3,8.9 h -24.2 c 7.1,-22.1 13.7,-44.8 21.2,-66.5"/>
        <path d="m 67.5,217.1 c 16.6,0.2 33.9,-0.3 50.1,0.2 -0.2,6.1 0.3,12.9 -0.2,18.7 H 89.7 v 5.6 H 114 c -0.2,5.7 0.3,12 -0.2,17.3 h -24 v 5.4 c 9.3,0.2 19.1,-0.3 28,0.2 -0.2,6.2 0.3,13.1 -0.2,18.9 H 67.5 Z"/>
        <path d="M 168.7,217.1 V 236 c -11.7,-0.7 -21.5,0.3 -24.9,7.9 -3.2,7.1 -0.1,15.1 5.6,18.2 4.6,2.5 11.7,2.2 19.4,2.1 v 19.1 c -21.6,1.3 -36.6,-1.7 -44.3,-13.8 -6.2,-9.7 -6.7,-24.5 -1.9,-35 6.7,-14.7 22.5,-19.3 46.1,-17.4"/>
        <path d="m 242.6,217.1 h 21.9 V 264 c 7.5,0.5 16.1,0.1 24,0.2 -2.1,6.4 -4.1,12.8 -6.3,19.1 h -39.9 v -65.5 c 0.1,-0.3 0,-0.6 0.3,-0.7"/>
        <path d="m 394.9,266.8 c 2.1,-2.1 1.9,-6.3 0.2,-8.4 -2.3,-2.8 -8,-1.6 -12.6,-1.9 -0.5,3.5 -0.1,11.7 -0.2,11.9 4.9,-0.3 10.2,0.8 12.6,-1.6 m -1.4,-26.1 c 2.5,-2.2 1.9,-7 -0.7,-8.4 -2.6,-1.5 -8.8,-0.7 -10.5,-0.7 v 10.3 c 4,-0.1 8.9,0.7 11.2,-1.2 m -32.7,-23.6 c 2.6,0 36.5,-3.6 47.6,2.8 6.6,3.8 11.3,14.4 6.1,23.3 -1.6,2.7 -4,4.3 -7.5,5.6 5.9,2.6 11.2,6.5 11.7,14.5 1.5,27 -33.8,18.8 -58.1,20.1 v -65.5 c 0,-0.4 0,-0.7 0.2,-0.8"/>
        <path d="m 426.4,218 c 1,0 2,0.3 3,0.8 1,0.5 1.7,1.3 2.3,2.3 0.5,1 0.8,2 0.8,3.1 0,1 -0.3,2.1 -0.8,3 -0.5,1 -1.3,1.7 -2.3,2.3 -1,0.5 -2,0.8 -3,0.8 -1,0 -2.1,-0.3 -3,-0.8 -1,-0.5 -1.7,-1.3 -2.3,-2.3 -0.5,-1 -0.8,-2 -0.8,-3 0,-1.1 0.3,-2.1 0.8,-3.1 0.5,-1 1.3,-1.7 2.3,-2.3 1,-0.6 2,-0.8 3,-0.8 z m 0,1 c -0.9,0 -1.7,0.2 -2.5,0.7 -0.8,0.4 -1.4,1.1 -1.9,1.9 -0.5,0.8 -0.7,1.7 -0.7,2.5 0,0.9 0.2,1.7 0.7,2.5 0.4,0.8 1.1,1.4 1.9,1.9 0.8,0.4 1.6,0.7 2.5,0.7 0.9,0 1.7,-0.2 2.5,-0.7 0.8,-0.5 1.4,-1.1 1.9,-1.9 0.4,-0.8 0.7,-1.6 0.7,-2.5 0,-0.9 -0.2,-1.7 -0.7,-2.5 -0.5,-0.8 -1.1,-1.4 -1.9,-1.9 -0.8,-0.5 -1.6,-0.7 -2.5,-0.7 z m -2.7,8.4 v -6.5 h 2.3 c 0.8,0 1.3,0.1 1.7,0.2 0.3,0.1 0.6,0.3 0.8,0.6 0.2,0.3 0.3,0.6 0.3,1 0,0.5 -0.2,0.9 -0.5,1.3 -0.3,0.4 -0.8,0.6 -1.4,0.6 0.2,0.1 0.4,0.2 0.6,0.3 0.3,0.3 0.6,0.7 1,1.3 l 0.8,1.3 H 428 l -0.6,-1 c -0.5,-0.8 -0.8,-1.3 -1.1,-1.5 -0.2,-0.2 -0.5,-0.2 -0.8,-0.2 h -0.6 v 2.8 z m 1.1,-3.7 h 1.3 c 0.6,0 1,-0.1 1.3,-0.3 0.2,-0.2 0.3,-0.4 0.3,-0.7 0,-0.2 -0.1,-0.4 -0.2,-0.5 -0.1,-0.2 -0.3,-0.3 -0.4,-0.3 -0.2,-0.1 -0.5,-0.1 -1.1,-0.1 h -1.2 z"/>
    </g>
</SvgImage>;

export default EcolabLogo;