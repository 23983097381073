// @flow

import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import JobList from "./job-list";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

const Jobs = () => {
    const classes = useStyles();

    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <Paper className={classes.paper}>
                <JobList/>
            </Paper>
        </Grid>
    </Grid>
}

export default Jobs;