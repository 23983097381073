// @flow

import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import {setAuthenticated} from "../../store/actions/auth";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const LoginForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [{ login }, setLogin] = useState({ login: '' });
    const [{ password }, setPassword] = useState({ password: '' });
    const [{ loginErrorText }, setLoginErrorText ] = useState( { loginErrorText: '' });
    const [{ passwordErrorText }, setPasswordErrorText ] = useState( { passwordErrorText: '' });

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoginErrorText({ loginErrorText: '' });
        setPasswordErrorText({ passwordErrorText: '' });

        if (!login || !password) {
            login || setLoginErrorText({ loginErrorText: 'Enter login' });
            password || setPasswordErrorText({ passwordErrorText: 'Enter password' });
        } else if (login === 'demo' && password === 'demo') {
            dispatch(setAuthenticated(true));
        } else {
            const errorText = 'Incorrect login or password';
            setLoginErrorText({ loginErrorText: errorText });
            setPasswordErrorText({ passwordErrorText: errorText });
        }
    };

    return <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="login"
            label="Login"
            name="login"
            autoComplete="login"
            autoFocus
            value={login}
            onChange={e => setLogin({login: e.target.value})}
            error={!!loginErrorText}
            helperText={loginErrorText}
        />
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword({password: e.target.value})}
            error={!!passwordErrorText}
            helperText={passwordErrorText}
        />
        <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
        />
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
        >
            Sign In
        </Button>
        {/*TODO might need below links*/}
        {/*<Grid container>
            <Grid item xs>
                <Link href="#" variant="body2">
                    Forgot password?
                </Link>
            </Grid>
            <Grid item>
                <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                </Link>
            </Grid>
        </Grid>*/}
    </form>;
}

export default LoginForm;