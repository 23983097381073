// @flow
import React, { useMemo, forwardRef } from "react";
import Link from "@material-ui/core/Link";
import {NavLink} from "react-router-dom";

export type Props = {
    children: mixed,
    to: mixed,
    activeClassName?: string,
    activeStyle?: {...},
    exact?: boolean,
    strict?: boolean,
    ...
};

const MenuItemLink = ({ children, to, activeClassName, activeStyle, exact, strict, ...rest }: Props) => {
    const renderLink = useMemo(
        () => forwardRef(
            (itemProps, ref) => <NavLink
                {...itemProps}
                to={to}
                ref={ref}
                activeClassName={activeClassName}
                activeStyle={activeStyle}
                exact={exact}
                strict={strict}
            />
        ),
        [to, activeClassName, activeStyle, exact, strict],
    );

    return <Link
        {...rest}
        component={renderLink}
    >
        {children}
    </Link>;
}

export default MenuItemLink;