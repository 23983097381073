// @flow
import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {setAuthenticated} from "../../../store/actions/auth";
import Tooltip from "@material-ui/core/Tooltip";
import EcolabLogo from "../../../icons/logo";
import Menu from "./menu";
import routes from "../../../routes";
import {Link} from "react-router-dom";

const useStyles = makeStyles(() => ({
    title: {
        flexGrow: 1,
    },
    logotypeLink: {
        color: "inherit",
    },
    logotype: {
        boxSizing: 'border-box',
        width: 160,
        marginRight: 10,
    },
}));

const Header = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleLogoutClick = () => {
        dispatch(setAuthenticated(false));
    }

    return (
        <AppBar>
            <Toolbar>
                <Link to={routes.dashboard} className={classes.logotypeLink}>
                    <EcolabLogo className={classes.logotype} color='inherit'/>
                </Link>
                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                    VPOM Dashboard
                </Typography>
                <Menu />
                <Tooltip title="Log out">
                    <IconButton color="inherit" onClick={handleLogoutClick}>
                        <ExitToAppIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </AppBar>
    );
};

export default Header;