// @flow

export type RoutesType = {|
    dashboard: string,
    jobs: string,
    printers: string,
    settings: string,
    login: string,
|}

const Routes: RoutesType = {
    dashboard: '/',
    jobs: '/jobs',
    printers: '/printers',
    settings: '/settings',
    login: '/login',
}

export default Routes;