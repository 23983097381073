// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './service-worker';
import store from "./store";
import {Provider as StoreProvider} from "react-redux";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

ReactDOM.render(
    <StoreProvider store={store}>
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </React.StrictMode>
    </StoreProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
