// @flow

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Chart from "./chart";
import RecentJobs from "./recent-jobs";
import React from "react";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Total from "./total";
import ServerStatus from "./server-status";
import PrinterStatus from "./printer-status";
import QueueStatus from "./queue-status";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const Dashboard = () => {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    return <Grid container spacing={3}>
        <Grid item xs={9}>
            <Paper className={fixedHeightPaper}>
                <Chart/>
            </Paper>
        </Grid>
        <Grid item xs={3}>
            <Paper className={fixedHeightPaper}>
                <Total/>
            </Paper>
        </Grid>
        <Grid item xs={4}>
            <Paper className={classes.paper}>
                <PrinterStatus/>
            </Paper>
        </Grid>
        <Grid item xs={4}>
            <Paper className={classes.paper}>
                <QueueStatus/>
            </Paper>
        </Grid>
        <Grid item xs={4}>
            <Paper className={classes.paper}>
                <ServerStatus/>
            </Paper>
        </Grid>
        <Grid item xs={12}>
            <Paper className={classes.paper}>
                <RecentJobs/>
            </Paper>
        </Grid>
    </Grid>
};

export default Dashboard;