// @flow
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import PrivateRoute from './components/routes/private';
import Jobs from "./pages/jobs";
import PrinterConfig from "./pages/printers";
import NotFound from "./pages/not-found";
import PageLayout from "./components/layouts/page-layout";
import routes from "./routes";
import Settings from "./pages/settings";

const App = () => <>
    <CssBaseline />
    <Router>
        <Switch>
            <PrivateRoute exact path={routes.dashboard}>
                <PageLayout>
                    <Dashboard/>
                </PageLayout>
            </PrivateRoute>
            <PrivateRoute path={routes.jobs}>
                <PageLayout>
                    <Jobs/>
                </PageLayout>
            </PrivateRoute>
            <PrivateRoute path={routes.printers}>
                <PageLayout>
                    <PrinterConfig/>
                </PageLayout>
            </PrivateRoute>
            <PrivateRoute path={routes.settings}>
                <PageLayout>
                    <Settings />
                </PageLayout>
            </PrivateRoute>
            <Route path={routes.login}>
                <Login />
            </Route>
            <PrivateRoute>
                <NotFound />
            </PrivateRoute>
        </Switch>
    </Router>
</>;

export default App;
