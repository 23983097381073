// @flow

import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Title from "../../components/app/title/title";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";

const useStyles = makeStyles(() => ({
    actions: {
        width: 100,
    },
}));

function createData(id, name, url, user) {
    return { id, name, url, user };
}

const rows = [
    createData(0, 'Server 1', 'https://vpom1.ecolab.com:8080', 'user1'),
    createData(1, 'Server 2', 'https://vpom2.ecolab.com:8080', 'user2'),
    createData(2, 'Server 3', 'https://vpom3.ecolab.com:8080', 'user3'),
    createData(3, 'Server 4', 'https://vpom4.ecolab.com:8080', 'user4'),
];

const ServerList = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Title>Servers</Title>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Url</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell className={classes.actions}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.url}</TableCell>
                                <TableCell>{row.user}</TableCell>
                                <TableCell>
                                    <Tooltip title="Edit">
                                        <IconButton size='small'>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton size='small'>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={5}
                page={0}
                onChangePage={() => {}}
            />
        </React.Fragment>
    );
};

export default ServerList;