// @flow
import { createMuiTheme } from '@material-ui/core/styles';

const getRgba = (r: number, g: number, b: number, a: number = 1.0) => `rgba(${r}, ${g}, ${b}, ${a})`;

//below are allowed to use colors, do not use any other colors

const PMS3005 = getRgba(0, 119, 200);
// const PMS3005_60 = getRgba(0, 119, 200, 0.6);
const PMS369 = getRgba(100, 167, 11);
// const PMS369_60 = getRgba(100, 167, 11, 0.6);

const PMS716 = getRgba(234, 118, 0);
// const PMS716_60 = getRgba(234, 118, 0, 0.6);
const PMS185 = getRgba(228, 0, 43);
// const PMS185_60 = getRgba(228, 0, 43, 0.6);

// const PMS106 = getRgba(249, 229, 71);
// const PMS106_60 = getRgba(249, 229, 71, 0.6);
const PMS3025 = getRgba(0, 79, 113);
// const PMS3025_60 = getRgba(0, 79, 113, 0.6);

// const PMS7409 = getRgba(240, 179, 35);
// const PMS7409_60 = getRgba(240, 179, 35, 0.6);
// const PMS2915 = getRgba(98, 181, 229);
// const PMS2915_60 = getRgba(98, 181, 229, 0.6);

const PMS7483 = getRgba(39, 93, 56);
// const PMS7483_60 = getRgba(39, 93, 56, 0.6);
// const PMS325 = getRgba(100, 204, 201);
// const PMS325_60 = getRgba(100, 204, 201, 0.6);

const DARK_GRAY = getRgba(100, 100, 100);
const DARK_GRAY_60 = getRgba(100, 100, 100, 0.6);
const LIGHT_GRAY = getRgba(201, 201, 201);
const LIGHT_GRAY_60 = getRgba(201, 201, 201, 0.6);
const LIGHT_GRAY_20 = getRgba(201, 201, 201, 0.2);

const theme = createMuiTheme({
    palette: {
        primary: {
            main: PMS3005,
        },
        secondary: {
            main: PMS369,
            contrastText: '#fff',
        },
        error: {
            main: PMS185,
        },
        warning: {
            main: PMS716,
        },
        info: {
            main: PMS3025,
        },
        success: {
            main: PMS7483,
        },
        text: {
            primary: DARK_GRAY,
            secondary: DARK_GRAY_60,
            disabled: LIGHT_GRAY,
        },
        action: {
            hover: LIGHT_GRAY_20,
            selected: LIGHT_GRAY_60,
            disabledBackground: LIGHT_GRAY,
            disabled: DARK_GRAY_60,
            active: DARK_GRAY,
        },
        background: {
            default: LIGHT_GRAY_20,
        },
    },
    typography: {
        fontFamily: [
            'Helvetica',
            'sans-serif',
        ].join(','),
    },
});

export default theme;