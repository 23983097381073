// @flow

import React from "react";
import Header from "../app/header";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Copyright from "../app/copyright";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}));

export type Props = {|
    children: mixed,
|};

const PageLayout = ({ children }: Props) => {
    const classes = useStyles();

    return <>
        <Header />
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                {children}

                <Box pt={4}>
                    <Copyright />
                </Box>
            </Container>
        </main>
    </>;
};

export default PageLayout;