// @flow

import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../components/app/title/title';
import { Link as RouterLink } from 'react-router-dom';
import routes from "../../routes";

function createData(id, date, name, printer, comment) {
    return { id, date, name, printer, comment };
}

const rows = [
    createData(0, '18 Mar, 2020', '2098', 'HP Color', 'Data transfer complete'),
    createData(1, '17 Mar, 2020', '2099', 'HP Color', 'Data transfer complete'),
    createData(2, '16 Mar, 2020', '2100', 'HP Color', 'Data transfer complete'),
    createData(3, '15 Mar, 2020', '2101', 'HP Color', 'Data transfer complete'),
    createData(4, '14 Mar, 2020', '2102', 'HP Color', 'Data transfer complete'),
];

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
}));

export default function RecentJobs() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Title>Recent Jobs</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Printer</TableCell>
                        <TableCell>Print Comment</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.date}</TableCell>
                            <TableCell>{row.printer}</TableCell>
                            <TableCell>{row.comment}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className={classes.seeMore}>
                <Link color="primary" component={RouterLink} to={routes.jobs}>
                    See more jobs
                </Link>
            </div>
        </React.Fragment>
    );
}