// @flow

import React from "react";
import Title from "../../components/app/title/title";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from '@material-ui/icons/GetApp';
import CancelIcon from '@material-ui/icons/Cancel';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import EditIcon from '@material-ui/icons/Edit';

function createData(id, time, name, printer, location, server, state, owner, jobId, pages) {
    return { id, time, name, printer, location, server, state, owner, jobId, pages};
}

const rows = [
    createData(0, '18 Mar, 2020 15:00', '2098', 'HP Color', 'Detroit', 'server1', 'Failure', 'user', '1', 10),
    createData(2, '16 Mar, 2020 15:00', '2100', 'HP Color', 'Detroit', 'server1', 'Ready', 'user', '2', 10),
    createData(3, '15 Mar, 2020 15:00', '2101', 'HP Color', 'Detroit', 'server1', 'Processing', 'user', '3', 10),
    createData(4, '14 Mar, 2020 15:00', '2102', 'HP Color', 'Detroit', 'server1', 'Success', 'user', '4', 10),
    createData(1, '17 Mar, 2020 15:00', '2099', 'HP Color', 'Detroit', 'server1', 'Initialized', 'user', '5', 10),
];

const JobList = () => {
    return (
        <React.Fragment>
            <Title>Jobs</Title>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Printer</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>VPOM Server</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell>Owner</TableCell>
                            <TableCell>Pages</TableCell>
                            <TableCell>Job ID</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.time}</TableCell>
                                <TableCell>{row.printer}</TableCell>
                                <TableCell>{row.location}</TableCell>
                                <TableCell>{row.server}</TableCell>
                                <TableCell>{row.state}</TableCell>
                                <TableCell>{row.owner}</TableCell>
                                <TableCell>{row.pages}</TableCell>
                                <TableCell>{row.jobId}</TableCell>
                                <TableCell>
                                    <Tooltip title="Redirect">
                                        <IconButton size='small'>
                                            <CallSplitIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Edit">
                                        <IconButton size='small'>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Download">
                                        <IconButton size='small'>
                                            <GetAppIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Cancel">
                                        <IconButton size='small'>
                                            <CancelIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={5}
                page={0}
                onChangePage={() => {}}
            />
        </React.Fragment>
    );
}

export default JobList;