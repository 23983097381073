// @flow
import React from 'react';
import routes from "../../../routes";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItemLink from "./menu-item-link";

export type MenuItem = {|
    label: string,
    path: string,
    exact: boolean,
|}

const menuItems: Array<MenuItem> = [
    {
        label: 'Dashboard',
        path: routes.dashboard,
        exact: true,
    },
    {
        label: 'Job overview',
        path: routes.jobs,
        exact: false,
    },
    {
        label: 'Printer config',
        path: routes.printers,
        exact: false,
    },
    {
        label: 'Settings',
        path: routes.settings,
        exact: false,
    },
];

const useStyles = makeStyles((theme) => ({
    link: {
        margin: theme.spacing(1, 1.5),
        fontSize: '1rem',
    },
    activeLink: {
        textDecoration: 'underline',
    }
}));

const Menu = () => {
    const classes = useStyles();

    return <nav>
        {menuItems.map((item) =>
            <MenuItemLink
                key={item.path}
                to={item.path}
                color='inherit'
                className={classes.link}
                activeClassName={classes.activeLink}
                exact={item.exact}
            >
                {item.label}
            </MenuItemLink>
        )}
    </nav>;
}

export default Menu;