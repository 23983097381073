// @flow
import { createStore } from 'redux';
import reducers from './reducers';
import type {AuthState} from "./reducers/auth";

export type State = {|
    auth: AuthState,
|}

const store = createStore(
    reducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;