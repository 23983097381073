// @flow

import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from '../../components/app/title/title';
import { Link as RouterLink } from 'react-router-dom';
import routes from "../../routes";

const useStyles = makeStyles({
    totalContext: {
        flex: 1,
    },
});

export default function Total() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Title>Total Jobs</Title>
            <div className={classes.totalContext}>
                <Typography component="p" variant="h4">
                    10,000
                </Typography>
                <Typography color="textSecondary">
                    This week
                </Typography>
                <Typography component="p" variant="h4">
                    30,000
                </Typography>
                <Typography color="textSecondary">
                    This month
                </Typography>
            </div>
            <div>
                <Link color="primary" component={RouterLink} to={routes.jobs}>
                    Overview jobs
                </Link>
            </div>
        </React.Fragment>
    );
}