// @flow
import { SET_AUTHENTICATED, type SetAuthenticatedAction } from '../actions/auth';

const AUTH_ITEM_NAME = 'isAuthenticated';

export type AuthState = {|
    isAuthenticated: boolean,
|}

export type AuthAction = | SetAuthenticatedAction;

const initialState: AuthState = {
    isAuthenticated: !!JSON.parse(sessionStorage.getItem(AUTH_ITEM_NAME) || 'false'),
}

const auth = (state: AuthState = initialState, action: AuthAction) => {
    switch(action.type) {
        case SET_AUTHENTICATED:
            sessionStorage.setItem(AUTH_ITEM_NAME, JSON.stringify(action.payload.isAuthenticated));
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}

export default auth;