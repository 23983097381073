// @flow
import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import ServerList from "./server-list";

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fab: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(1.5),
    },
}));

const Settings = () => {
    const classes = useStyles();

    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <Paper className={classes.paper}>
                <Fab variant="extended" size="small" color="secondary" aria-label="add" className={classes.fab}>
                    <AddIcon />
                    Add server
                </Fab>
                <ServerList/>
            </Paper>
        </Grid>
    </Grid>
};

export default Settings;