// @flow

import React from 'react';
import EcolabLogo from "../../icons/logo";
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Home from "@material-ui/icons/Home";
import IconButton from "@material-ui/core/IconButton";
import { Link as RouterLink } from 'react-router-dom';
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
    container: {
        boxSizing: 'border-box',
        height: '100vh',
        padding: theme.spacing(2),
    },
    logotype: {
        display: 'block',
        maxWidth: 260,
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    message: {
        textAlign: 'center',
    }
}));

const NotFound = () => {
    const classes = useStyles();

    return (
        <Grid container direction='column' justify='center' alignItems='center' className={classes.container}>
            <Grid item>
                <EcolabLogo className={classes.logotype} color='primary' />
            </Grid>
            <Grid item className={classes.message}>
                <Typography variant="h4">Page not found</Typography>
                <Typography variant="subtitle1">We are sorry but the page you are looking for does not exist.</Typography>
            </Grid>
            <Grid item>
                <Tooltip title="Go home">
                    <IconButton
                        color="primary"
                        aria-label="home"
                        component={RouterLink}
                        to="/"
                    >
                        <Home fontSize="large" />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default NotFound;