// @flow

import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from "../../components/app/copyright";
import {useSelector} from "react-redux";
import {getAuthenticated} from "../../store/selectors";
import {Redirect, useLocation} from "react-router-dom";
import LoginForm from "./form";
import EcolabLogo from "../../icons/logo";
import routes from "../../routes";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logoWrapper: {
        boxSizing: 'border-box',
        maxWidth: '260px',
        padding: `0 ${theme.spacing(3)}px`,
    },
    logotype: {
        display: 'block',
        width: '100%',
    },
}));

const Login = () => {
    const classes = useStyles();
    const isAuthenticated = useSelector(getAuthenticated);
    const location = useLocation();

    return isAuthenticated
        ? <Redirect
            to={location.state && location.state.referrer
                ? location.state.referrer
                : {pathname: routes.dashboard}}
        />
        : <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <div className={classes.logoWrapper}>
                    <EcolabLogo className={classes.logotype} color='primary' />
                </div>
                <LoginForm />
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>;
}

export default Login;